*, 
*::after, 
*::before {
  box-sizing: border-box;
}

* {
  margin: 0px;
  padding: 0px;
  color: #797979;
}

html {
  color-scheme: dark light;
}

body {
  min-height: 100vh;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edede9;
}


